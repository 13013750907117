<template>
    <div>
        <v-data-table
            id="campaigns-table"
            ref="datatable"
            :items="consolidatedResult"
            :headers="platformObjectHeaders"
            item-key="platform"
            :expand="true"
            class="pb-4"
            :style="{ marginTop: index === 0 ? '100px' : '0' }"
            hide-actions
            :hide-headers="index > 0">
            <template
                slot="headerCell"
                slot-scope="props">
                <div
                    v-if="props.header.text === 'SPEND'"
                    class="spend-header">
                    <span>SPEND</span>
                    <v-select
                        v-model="localSpendOpt"
                        :items="spendOptions"
                        item-text="text"
                        item-value="value"
                        class="spend-dropdown mt-1"
                        @change="onSpendOptionChange">
                        <template #item="{ item }">
                            <v-checkbox
                                v-model="localSpendOpt"
                                :value="item.value"
                                :label="item.text"
                                @change="onSpendOptionChange" />
                        </template>
                    </v-select>
                </div>
                <div
                    v-else-if="props.header.text === 'impr'"
                    class="divided-wrapper">
                    <span class="divided">IMPR</span>
                    <div>CPM</div>
                </div>
                <div
                    v-else-if="props.header.text === 'clicks'"
                    class="divided-wrapper">
                    <span class="divided">CLICKS</span>
                    <div>CPC</div>
                </div>
                <div
                    v-else-if="props.header.text === 'vc'"
                    class="divided-wrapper">
                    <span class="divided">VC</span>
                    <div>CPVC</div>
                </div>
                <div
                    v-else-if="props.header.text === 'lpv'"
                    class="divided-wrapper">
                    <span class="divided">LPV</span>
                    <div class="divided-2">
                        CPLPV
                    </div>
                </div>
                <div
                    v-else-if="props.header.text === 'leads'"
                    class="divided-wrapper">
                    <span class="divided">LEADS</span>
                    <div class="divided-2">
                        CPL
                    </div>
                </div>
                <div
                    v-else-if="props.header.text === 'sales'"
                    class="divided-wrapper">
                    <span class="divided">SALES</span>
                    <div class="divided-2">
                        CPS
                    </div>
                </div>
                <span v-else>{{ props.header.text }}</span>
            </template>
            <template #items="props">
                <tr
                    :ref="'blueBg' + index"
                    class="blue-bg"
                    :style="getStickyStyle(index)">
                    <td class="centered w2">
                        <button
                            class="pt-1"
                            @click="isPlatformExpandedToggle">
                            <icon
                                size="20"
                                :name="isPlatformExpanded ? 'chevron-up' : 'chevron-down'" />
                        </button>
                    </td>
                    <td class="w2">
                        <styled-checkbox
                            v-if="!loading"
                            class="h15"
                            :row="false"
                            :disabled="Boolean(error)"
                            :checked="Boolean(selectedAds.length)"
                            @change="toggleSelectAll" />
                        <loader
                            v-else
                            size="18" />
                    </td>
                    <td class="w5">
                        <div class="channel-wrapper">
                            <icon
                                v-if="statsAreProcessing.status === 'running'"
                                class="channel-warning"
                                size="15"
                                :color="colors.yellow"
                                name="indicator-warning" />
                            <icon
                                v-if="statsAreProcessing.status === 'failed'"
                                class="channel-warning"
                                size="15"
                                :color="colors.red"
                                name="indicator-error" />
                            <icon
                                size="20"
                                :name="fbToMeta(props.item.platform, true)" />
                            <span class="channel-name">{{
                                fbToMeta(capitalizeFirstLetter(props.item.platform))
                            }}</span>
                            <div class="tooltip-container">
                                <styled-tooltip
                                    v-if="isPinterest(props.item.platform) && !loading"
                                    class="pinterest-tooltip"
                                    position="top">
                                    <template #content>
                                        Pinterest does not allow platforms like BuyerBridge to view dynamic ads
                                        outside of their ads manager.
                                        You can view deployed dynamic sets on the sets tab or use the menu on the
                                        Pinterest channel row to open the dynamic ads directly within Pinterest’s
                                        ads manager.
                                    </template>
                                    <icon
                                        name="question-circle"
                                        size="14"
                                        color="gray" />
                                </styled-tooltip>
                            </div>
                            <div
                                v-if="statsAreProcessing.value"
                                class="channel-processing"
                                :class="{'failed': statsAreProcessing.status === 'failed'}">
                                <styled-tooltip
                                    nudge-top="10"
                                    position="top">
                                    <template #content>
                                        <div v-if="statsAreProcessing.status === 'running'">
                                            {{ capitalizeFirstLetter(props.item.platform) }} stats are currently
                                            being populated for this date range.
                                            Please check back soon to see your data!
                                        </div>
                                        <div
                                            v-else-if="statsAreProcessing.status === 'failed' && props.item.platform === PLATFORM_PINTEREST">
                                            {{ capitalizeFirstLetter(props.item.platform) }} stats processing
                                            failed. Sometimes this can be resolved by clicking retry.
                                            If not, click the info icon for additional steps to resolve the issue.
                                        </div>
                                        <div v-else>
                                            There was an issue getting your data from
                                            {{ capitalizeFirstLetter(props.item.platform) }}.
                                            Click the info icon for additional steps to resolve this issue and if
                                            you are unable to resolve please contact support
                                        </div>
                                    </template>
                                    <div class="info-failed">
                                        <v-progress-circular
                                            v-if="statsAreProcessing.status === 'running'"
                                            class="mr-2"
                                            size="15"
                                            width="2"
                                            color="primary"
                                            indeterminate />
                                        <span
                                            v-if="statsAreProcessing.status === 'running'">Stats processing...</span>
                                        <span
                                            v-else-if="statsAreProcessing.status === 'failed' && props.item.platform === PLATFORM_PINTEREST">Stats failed</span>
                                        <span v-else>Connection failed</span>
                                        <div
                                            v-if="statsAreProcessing.status === 'failed'"
                                            class="info-failed">
                                            <a
                                                v-if="props.item.platform === PLATFORM_PINTEREST"
                                                class="ml-2"
                                                @click="makeSyncAndRetry(props.item.platform)">retry</a>
                                            <span @click="checkConnection(props.item.platform)">
                                                <icon
                                                    class="ml-2 info-icon"
                                                    size="15"
                                                    name="indicator-info" />
                                            </span>
                                        </div>
                                    </div>
                                </styled-tooltip>
                            </div>
                        </div>
                    </td>
                    <td class="w4">
                        &nbsp;
                    </td>
                    <td
                        class="centered w5"
                        style="padding-left: 66px;">
                        <status-toggle
                            v-if="!loading && !objectCountLoading"
                            :value="filterStatus"
                            :count="isFiltered ? undefined : props.item.status.noOfActives"
                            @input="changeFilterStatus" />
                        <loader
                            v-else
                            size="18" />
                    </td>
                    <td class="w5">
                        &nbsp;
                    </td>
                    <td class="centered darken w5">
                        <empty-field v-if="statsAreProcessing.value" />
                        <template v-else-if="!isFiltered">
                            {{ formatNumber(props.item.spend, 'currency') }}
                        </template>
                    </td>
                    <td class="centered darken w4">
                        <empty-field v-if="statsAreProcessing.value" />
                        <template v-else-if="!isFiltered">
                            {{ formatNumber(props.item.frequency, 'number') }}
                        </template>
                    </td>
                    <td class="centered darken w4 totals-tooltip-cell">
                        <totals-tooltip
                            v-if="!isFiltered"
                            type="ads" />
                        <empty-field v-if="statsAreProcessing.value" />
                        <div
                            v-else-if="!isFiltered"
                            class="divided-wrapper">
                            <span class="divided">{{ formatNumber(props.item.impressions, 'number') }}</span>
                            <div class="divided-2">
                                {{ formatNumber(props.item.cpm, 'currency') }}
                            </div>
                        </div>
                    </td>
                    <td class="centered darken w4">
                        <empty-field v-if="statsAreProcessing.value" />
                        <div
                            v-else-if="!isFiltered"
                            class="divided-wrapper">
                            <span class="divided">{{ formatNumber(props.item.clicks, 'number') }}</span>
                            <div class="divided-2">
                                {{ formatNumber(props.item.cost_per_click, 'currency') }}
                            </div>
                        </div>
                    </td>
                    <td class="centered darken w4">
                        <empty-field v-if="statsAreProcessing.value" />
                        <div
                            v-else-if="!isFiltered"
                            class="divided-wrapper">
                            <span class="divided">{{ formatNumber(props.item.view_content, 'number') }}</span>
                            <div class="divided-2">
                                {{ formatNumber(props.item.cost_per_view_content, 'currency') }}
                            </div>
                        </div>
                    </td>
                    <td class="centered darken w4">
                        <empty-field v-if="statsAreProcessing.value" />
                        <div
                            v-else-if="!isFiltered"
                            class="divided-wrapper">
                            <span class="divided">
                                {{ props.item.landing_page_view ? formatNumber(props.item.landing_page_view, 'number') : 'N/A'
                                }}
                            </span>
                            <div class="divided-2">
                                {{ props.item.cost_per_landing_page_view ? formatNumber(props.item.cost_per_landing_page_view, 'currency') : 'N/A'
                                }}
                            </div>
                        </div>
                    </td>
                    <td class="centered darken w4">
                        <empty-field v-if="statsAreProcessing.value" />
                        <div
                            v-else-if="!isFiltered"
                            class="divided-wrapper">
                            <span class="divided">{{ formatNumber(props.item.leads, 'number') }}</span>
                            <div class="divided-2">
                                {{ formatNumber(props.item.cost_per_lead, 'currency') }}
                            </div>
                        </div>
                    </td>
                    <td class="centered darken w4">
                        <empty-field v-if="platform === PLATFORM_TIKTOK || statsAreProcessing.value" />
                        <div
                            v-else-if="!isFiltered"
                            class="divided-wrapper">
                            <span class="divided">{{ formatNumber(props.item.sales, 'number') }}</span>
                            <div class="divided-2">
                                {{ formatNumber(props.item.cost_per_sale, 'currency') }}
                            </div>
                        </div>
                    </td>
                    <td class="darken w2">
                        <vertical-menu>
                            <a
                                class="menu-link"
                                :href="adsManagerLink(props.item.platform)"
                                target="_blank">
                                View {{ capitalizeFirstLetter(props.item.platform) }} Ads Manager
                            </a>
                            <a
                                v-if="platform === PLATFORM_PINTEREST"
                                class="menu-link with-line"
                                :href="dealerPinterestDynamicProductUrl"
                                target="_blank">
                                View {{ capitalizeFirstLetter(props.item.platform) }} Dynamic Product Ads
                            </a>
                        </vertical-menu>
                    </td>
                </tr>
            </template>
            <template
                v-if="isPlatformExpanded"
                #expand="{ item }">
                <table
                    v-if="!error"
                    class="expand-table">
                    <!-- active platform object -->
                    <tbody
                        v-for="page in pages"
                        :key="page.key">
                        <table-row
                            v-for="ad in page.objects"
                            :key="createHashKey(ad)"
                            :platform-object="ad"
                            :channel-name="item.platform"
                            :loading-lazy="page.loading"
                            :running="page.running"
                            type="ads"
                            :selected="selectedAds.includes(ad.external_id)"
                            @select="toggleAdSelection(ad, $event)"
                            @update-ad="updateAd" />
                    </tbody>
                </table>
                <div
                    v-if="loading || loadingNextPage"
                    class="text-xs-center">
                    <styled-loader
                        :size="18"
                        class="load-more" />
                </div>
                <div
                    v-if="error"
                    class="error-section">
                    <p>{{ error }}</p>
                </div>
                <div
                    v-if="!loading && !allAds.length"
                    class="no-data">
                    <p>There are no ads for the selected criteria.</p>
                </div>
                <div v-if="Boolean(nextPage) && !error && allAds.length < platformObjectCount">
                    <div class="load-more-blue">
                        <div
                            :key="pages.length"
                            class="link"
                            @click="onNextPage">
                            <p class="mb-0">
                                {{ allAds.length + ' of ' + platformObjectCount }}
                            </p>
                            <p> {{ 'Load More' }} </p>
                        </div>
                    </div>
                </div>
            </template>
        </v-data-table>
    </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import { getChannelByPlatformName } from '@/components/onboarding/channel-setup/channels';
import VerticalMenu from '@/components/globals/VerticalMenu';
import EmptyField from '@/components/globals/EmptyField';
import StyledCheckbox from '@/components/globals/StyledCheckbox';
import StyledLoader from '@/components/globals/StyledLoader';
import Icon from '@/components/globals/Icon';
import Loader from '@/components/globals/StyledLoader.vue';
import numeral from 'numeral';
import getNumberFormats from '@/helpers/numberFormats';
import TotalsTooltip from '../TotalsTooltip';
import TableRow from '../TableRow';
import StatusToggle from '../StatusToggle';
import colors from '@/helpers/colors';
import {
    OBJECT_TYPE_AD,
    PLATFORM_FACEBOOK,
    PLATFORM_PINTEREST,
    PLATFORM_SNAPCHAT,
    PLATFORM_TIKTOK
} from '@/helpers/globals';
import getObjectFromPages from '../helpers/getObjectFromPages';
import mergeEditedObject from '../helpers/mergeEditedObject';
import StyledTooltip from '@/components/globals/StyledTooltip';
import { SET_CHANNEL_CONNECTION_DEBUG_PANEL } from '@/store/mutation-types';
import { fbToMeta } from '@/helpers/fbToMeta';
import { format } from 'date-fns';
import createHashKey from '@/helpers/createHashKey';

export default {
    components: {
        VerticalMenu,
        EmptyField,
        StatusToggle,
        TotalsTooltip,
        StyledCheckbox,
        StyledLoader,
        Icon,
        Loader,
        TableRow,
        StyledTooltip
    },
    props: {
        platform: {
            type: String,
            required: true
        },
        search: {
            type: String,
            default: ''
        },
        spendOption: {
            type: String,
            default: 'All'
        },
        index: {
            type: Number,
            required: true
        },
        scrollPosition: {
            type: Number,
            required: true
        }
    },
    data() {
        return {
            colors,
            statsAreProcessing: {
                value: false,
                status: ''
            },
            error: '',
            nextPage: null,
            pages: [],
            loading: false,
            initialSelectedAds: [],
            loadingNextPage: false,
            showStatusFilter: false,
            isPlatformExpanded: true,
            filterStatus: 'ACTIVE',
            objectCountLoading: false,
            platformObjectCount: 0,
            firstInit: true,
            PLATFORM_TIKTOK,
            PLATFORM_PINTEREST,
            spendOptions: [
                { text: 'All Campaigns', value: 'All' },
                { text: 'Has Spend', value: 'Has' },
                { text: 'No Spend', value: 'No' }
            ],
            localSpendOpt: 'All',
            fbToMeta,
            createHashKey
        };
    },
    computed: {
        ...mapState({
            currentDealerId: (state) => state.dealer.currentDealerId,
            dateRange: (state) => state.metrics.dateRange
        }),
        ...mapGetters('playbookManagement', [
            'getNormalizedMetricTotals'
        ]),
        ...mapGetters([
            'dealerFacebookAdAccountUrl',
            'dealerTiktokAdAccountUrl',
            'dealerSnapchatAdAccountUrl',
            'dealerPinterestAdAccountUrl',
            'dealerPinterestDynamicProductUrl'
        ]),
        selectedCampaigns() {
            return this.$store.state.playbookManagement.selectedCampaigns[this.platform];
        },
        selectedAdSets() {
            return this.$store.state.playbookManagement.selectedAdSets[this.platform];
        },
        selectedAds() {
            return this.$store.state.playbookManagement.selectedAds[this.platform];
        },
        isFiltered() {
            return Boolean(
                this.selectedCampaigns.length ||
                this.selectedAdSets.length ||
                this.initialSelectedAds.length
            );
        },
        pageSize() {
            return 10;
        },
        channelDetails() {
            return getChannelByPlatformName(this.platform);
        },
        platformObjectHeaders() {
            return [
                {
                    text: '',
                    align: '',
                    sortable: false,
                    width: '2%'
                },
                {
                    text: '',
                    align: 'center',
                    sortable: false,
                    width: '2%',
                    value: 'data-table-expand'
                },
                {
                    text: 'CHANNEL',
                    align: 'left',
                    sortable: false,
                    value: 'channel',
                    width: '6%'
                },
                {
                    text: 'AD',
                    align: 'left',
                    sortable: false,
                    value: 'channel',
                    width: '3%'
                },
                {
                    text: 'STATUS',
                    align: 'center',
                    sortable: false,
                    width: '5%'
                },
                {
                    text: 'SET',
                    align: 'left',
                    sortable: false,
                    width: '4%'
                },
                {
                    text: 'SPEND',
                    align: 'left',
                    sortable: false,
                    width: '1%'
                },
                {
                    text: 'FREQ',
                    align: 'center',
                    sortable: false,
                    width: '1%'
                },
                {
                    text: 'impr',
                    align: 'center',
                    sortable: false,
                    width: '4%'
                },
                {
                    text: 'clicks',
                    align: 'center',
                    sortable: false,
                    width: '4%'
                },
                {
                    text: 'vc',
                    align: 'center',
                    sortable: false,
                    value: 'view_content',
                    width: '4%'
                },
                {
                    text: 'lpv',
                    align: 'center',
                    sortable: false,
                    value: 'landing_page_view',
                    width: '4%'
                },
                {
                    text: 'leads',
                    align: 'center',
                    sortable: false,
                    value: 'leads',
                    width: '4%'
                },
                {
                    text: 'sales',
                    align: 'center',
                    sortable: false,
                    value: 'sales',
                    width: '4%'
                },
                {
                    text: '',
                    align: '',
                    sortable: false,
                    width: '1%'
                }
            ];
        },
        consolidatedResult() {
            return [{
                platform: this.platform,
                status: {
                    type: this.getOverallChannelStatus,
                    noOfActives: this.adCount
                },
                ...this.getNormalizedMetricTotals(this.platform) ?? {}
            }];
        },
        allAds() {
            return this.pages.reduce((all, current) => {
                all = all.concat(current.objects);
                return all;
            }, []);
        },
        getOverallChannelStatus() {
            return this.filterStatus === 'ACTIVE' ? 'active' : '';
        },
        adCount() {
            return this.platformObjectCount;
        }
    },
    watch: {
        allAds(objects) {
            this.setPlatformObjects({
                type: OBJECT_TYPE_AD,
                platform: this.platform,
                objects
            });
        },
        search() {
            this.init();
        },
        spendOption(newVal) {
            this.localSpendOpt = newVal;
            this.init();
        }
    },
    mounted() {
        this.init();
        this.$nextTick(() => {
        });
    },
    methods: {
        ...mapActions('playbookManagement', {
            selectObjects: 'select',
            unselectObjects: 'unselect',
            setPlatformObjects: 'setPlatformObjects'
        }),
        checkConnection(channel) {
            this.$store.commit(SET_CHANNEL_CONNECTION_DEBUG_PANEL, {
                status: true,
                channel,
                dealer_id: this.currentDealerId
            });
        },
        async makeSyncAndRetry(channel) {
            await this.makeFullSync(channel);
            await this.init();
        },
        async makeFullSync(channel) {
            this.loading = true;
            try {
                await this.$apiRepository.queueDealerAdStatsSync({ dealerId: this.currentDealerId, platform: channel });
            } catch (error) {
                console.log(error);
            } finally {
                this.loading = false;
            }
        },
        async init() {
            this.statsAreProcessing = {
                value: false,
                status: ''
            };
            this.pages = [];
            this.nextPage = null;

            // If the reports loads with ads selected this means
            // the user wants to target these but we don't want to start
            // filtering them out when paging so we need cache the first
            // values for loading
            this.initialSelectedAds = [...this.selectedAds];
            this.$refs.datatable.expanded[this.platform] = true;
            this.loading = true;

            // Set filter to 'ANY' just for the first time, then the user selection applies
            if (this.isFiltered && this.firstInit) {
                this.filterStatus = '';
            }
            this.firstInit = false;

            await Promise.all([
                await this.getAdsPage(),
                await this.getPlatformObjectCount()
            ]);

            this.loading = false;
        },
        async onNextPage() {
            if (!this.loadingNextPage && this.nextPage) {
                this.loadingNextPage = true;
                await this.getAdsPage();
                this.loadingNextPage = false;
            }
        },
        async changeFilterStatus(value) {
            this.filterStatus = value;
            this.showStatusFilter = false;
            this.init();
        },
        async getAdsPage() {
            try {
                // FIXME: This is due to us not having the ability to do backend filters + pagination properly
                let page_size;
                if (this.platform === PLATFORM_TIKTOK) {
                    page_size = this.localSpendOpt !== 'All'  ? 100 : this.pageSize;
                } else {
                    page_size =  this.localSpendOpt !== 'All' ? this.platformObjectCount : this.pageSize;
                }

                const params = {
                    start_date: format(this.dateRange.startDate, 'YYYY-MM-DD'),
                    end_date: format(this.dateRange.endDate, 'YYYY-MM-DD'),
                    stats: true,
                    creative: true,
                    page_size
                };

                if (this.initialSelectedAds.length) {
                    params.adIds = this.initialSelectedAds;
                } else if (this.selectedAdSets.length) {
                    params.adSetIds = this.selectedAdSets;
                } else if (this.selectedCampaigns.length) {
                    params.campaignIds = this.selectedCampaigns;
                }

                if (this.search) {
                    params.search = this.search;
                }

                if (this.filterStatus) {
                    params.status = this.filterStatus;
                }

                if (this.localSpendOpt === 'No' || this.localSpendOpt === 'Has') {
                    params.spend = this.localSpendOpt;
                }

                if (this.nextPage) {
                    params.page = this.nextPage;
                }

                const response = await this.$apiRepository.getDealerPlatformAds({
                    dealerId: this.currentDealerId,
                    platform: this.platform,
                    params
                });

                if (response.data.data.length) {
                    const pageKey = this.nextPage;

                    this.pages.push({
                        key: pageKey,
                        loading: false,
                        running: false,
                        objects: response.data.data
                    });
                }

                // If there's another page to load track it
                this.nextPage = response.data?.meta?.next_page || null;
            } catch (error) {
                this.statsAreProcessing = {
                    value: true,
                    status: 'failed'
                };
                this.error = 'There was an error retrieving data from this platform.  Please try resetting the report or contact support.';
            }
        },
        async getPlatformObjectCount() {
            try {
                this.objectCountLoading = true;
                const params = {
                    type: 'ad'
                };

                if (this.initialSelectedAds.length) {
                    params.adIds = this.initialSelectedAds;
                } else if (this.selectedAdSets.length) {
                    params.adSetIds = this.selectedAdSets;
                } else if (this.selectedCampaigns.length) {
                    params.campaignIds = this.selectedCampaigns;
                }

                if (this.filterStatus) {
                    params.status = this.filterStatus;
                }

                if (this.localSpendOpt === 'No' || this.localSpendOpt === 'Has') {
                    params.spend = this.localSpendOpt;
                }

                if (this.search) {
                    params.search = this.search;
                }

                const response = await this.$http.get(`/dealers/${this.currentDealerId}/platforms/${this.platform}/ads/count`, params);
                this.platformObjectCount = response.data?.count ?? 0;
            } catch (error) {
                this.error = 'There was an error retrieving data from this platform.  Please try resetting the report or contact support.';
                console.log(this.error, error.response || error);
            } finally {
                this.objectCountLoading = false;
            }
        },
        toggleAdSelection(ad, checked) {
            if (checked) {
                this.selectObjects({
                    platform: this.platform,
                    type: OBJECT_TYPE_AD,
                    objects: [ad.external_id]
                });
            } else {
                this.unselectObjects({
                    platform: this.platform,
                    type: OBJECT_TYPE_AD,
                    objects: [ad.external_id]
                });
            }
        },
        toggleSelectAll(event) {
            const { checked } = event.target;

            if (checked) {
                this.selectObjects({
                    platform: this.platform,
                    type: OBJECT_TYPE_AD,
                    objects: this.allAds.map(ad => ad.external_id)
                });
            } else {
                this.unselectObjects({
                    platform: this.platform,
                    type: OBJECT_TYPE_AD,
                    objects: this.allAds.map(ad => ad.external_id)
                });
            }
        },
        updateAd(ad) {
            const {
                pageIndex,
                objectIndex,
                object: oldAd
            } = getObjectFromPages(this.pages, ad.external_id);

            if (oldAd) {
                const newAd = mergeEditedObject(oldAd, ad);
                const page = { ...this.pages[pageIndex] };
                page.objects.splice(objectIndex, 1, newAd);
                this.$set(this.pages, pageIndex, page);
            }
        },
        formatNumber(number, type) {
            const formats = getNumberFormats();

            if (!number && type === 'number') {
                return 0;
            }

            return numeral(number).format(formats[type]);
        },
        capitalizeFirstLetter(string) {
            return string.charAt(0).toUpperCase() + string.slice(1);
        },
        reset() {
            this.pages = [];
            this.filterStatus = 'ACTIVE';
            this.localSpendOpt = 'All';
            this.nextPage = null;
        },
        isPinterest(itemName) {
            return itemName === PLATFORM_PINTEREST;
        },
        adsManagerLink(channel) {
            switch (channel) {
                case PLATFORM_FACEBOOK:
                    return this.dealerFacebookAdAccountUrl;
                case PLATFORM_TIKTOK:
                    return this.dealerTiktokAdAccountUrl;
                case PLATFORM_SNAPCHAT:
                    return this.dealerSnapchatAdAccountUrl;
                case PLATFORM_PINTEREST:
                    return this.dealerPinterestAdAccountUrl;
            }
        },
        isPlatformExpandedToggle() {
            this.isPlatformExpanded = !this.isPlatformExpanded;
        },
        onSpendOptionChange(spendOption) {
            this.localSpendOpt = spendOption;
            this.$emit('spend-option-changed', spendOption);
        },
        isSticky() {
            const refName = 'blueBg' + this.index;
            if (this.$refs[refName]) {
                const rowTop = this.$refs[refName].getBoundingClientRect().top;
                return rowTop <= 0;
            }
            return false;
        },
        getStickyStyle(index) {
            return this.isSticky ? { top: '80px', position: 'sticky', zIndex: 10 - index } : {};
        }
    }
};
</script>

<style lang="scss" scoped>
.load-more {
    margin-top: 10px;
}

.no-data {
    padding: 30px 50px 10px;
}

.error-section {
    padding: 30px 50px 10px;
    color: $error-500;
}

.pinterest-tooltip {
    margin-left: 7px;
}

.tooltip-container {
    display: inline;
    position: absolute;
}

.menu-link {
    display: block;
    white-space: nowrap;
    margin-right: 5px;
}

.with-line {
    margin-top: 30px;
    position: relative;

    &:after {
        content: '';
        position: absolute;
        top: -15px;
        left: -5px;
        right: -5px;
        width: calc(100% + 10px);
        height: 1px;
        background: $border-list;
    }
}

.channel-wrapper {
    position: relative;
}

.channel-warning {
    position: absolute;
    z-index: 9;
    background: white;
    border-radius: 50%;
    top: -5px;
    left: -7px;
}

.channel-processing {
    display: flex;
    align-items: center;
    position: absolute;
    left: calc(100% + 5px);
    top: 50%;
    margin-top: -13px;
    white-space: nowrap;
    font-size: 10px;
    color: $gray;
    background: $lightblue-bg;
    padding: 5px 10px;
    border-radius: 5px;
    z-index: 9;
}

.channel-processing.failed {
    background: $light-error;
}

.info-icon {
    cursor: pointer;
}

.info-failed {
    display: flex;
    align-items: center;
}

.load-more-blue {
    background: #dcf2fc;
    justify-content: center;
    display: flex;
    padding: 4px;
    text-align: center;
}

.link {
    color: $blue-primary;
    text-decoration: underline;
    cursor: pointer;
}

::v-deep thead {
    position: absolute;
    width: 98%;
    background-color: white;
    z-index: 999;
    margin-top: -100px;
}

.table-header th {
    position: sticky;
    top: 0;
    background-color: white; /* Ensure it has a background color */
    z-index: 100; /* Make sure it's above other elements */
}
</style>
